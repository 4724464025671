import { useState, useEffect } from 'react';
import { cachedQixApp } from './qlikLib';

/* https://github.com/cwise89/react-detect-offline/blob/master/src/index.js */
const unsupportedUserAgentsPattern = /Windows.*Chrome|Windows.*Firefox|Linux.*Chrome/;
export function useIsOnline() {
  const [isOnline, setIsOnline] = useState(true);

  const onOffline = () => setIsOnline(false);
  const onOnline = () => setIsOnline(true);

  useEffect(() => {

    // This fallback is for cases like Chrome on Windows
    // which does not have full support for offline event
    let offlineNotSupportedFallbackInterval = null;
    if (unsupportedUserAgentsPattern.test(navigator.userAgent)) {
      offlineNotSupportedFallbackInterval = setInterval(async () => {
        try {
          await fetch("https://httpbin.org/get");
          onOnline();
        } catch {
          onOffline();
        }
      }, 3000);
    }


    // When the browser disconnects close the session to trigger manual error handling
    window.addEventListener('offline', onOffline);

    // When the browser reconnects try creating the session again
    window.addEventListener('online', onOnline);

    // Clean
    return () => {
      window.removeEventListener('offline', onOffline);
      window.removeEventListener('online', onOnline);

      if(offlineNotSupportedFallbackInterval) {
        clearInterval(offlineNotSupportedFallbackInterval);
      }
    }
  }, []);

  return isOnline;
}

export function useIsEnigmaConnected() {
  const isOnline = useIsOnline();
  const [isConnected, setIsConnected] = useState(true);

  useEffect(() => {
    const disconnected = () => setIsConnected(false);
    
    window.addEventListener("enigma:closed", disconnected);
    window.addEventListener("enigma:suspended", disconnected);

    // Clean
    return () => {
      window.removeEventListener("enigma:closed", disconnected);
      window.removeEventListener("enigma:suspended", disconnected);
    }
  }, []);

  return isConnected && isOnline;
}