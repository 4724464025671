import "./Header.scss";
import { Link } from "react-router-dom";
import { Component } from "react";
import arrow from "../assets/arrow.png";
import search from "../assets/search.png";
import alert from "../assets/alerts.png";

class Header extends Component {
  render() {
    return (
      <>
        <div className="header-component">
          <h3 className="header-title">Vehicle Overview</h3>

          <div className="header-sub__component">
            <div className="divider">
              <div className="header-assets">
                <p>Asset ID: A34078</p>
                <img className="arrow" src={arrow} alt="arrow" />
              </div>
              <div className="search-component">
                <p>Select Date</p>
                <img className="arrow" src={arrow} alt="arrow" />
              </div>
            </div>
            <div className="header-upper-right">
              <img className="header-search" src={search} alt="search" />
              <img className="header-alert" src={alert} alt="alert" />
              <div className="header-data">
                <p>Tosca Mining</p>
                <p>Export Data</p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Header;
