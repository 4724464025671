import "./LogOut.scss";
import { Auth } from "aws-amplify";
import { useAuthContext } from "../../lib/contextLib";
import { logout as qlikLogout } from "../../lib/qlikLib";
import { AuthStates } from "../../types";
import BigButton from "./BigButton";

function LogOutButton() {
  const { setAuthState } = useAuthContext();

  async function onLogout() {
    await Auth.signOut();
    setAuthState(AuthStates.loadingData);
    await qlikLogout()
    window.location.reload()
  }

  return <BigButton
    className="logout-button"
    onClick={onLogout}
    type="submit"
  >
    Log Out
  </BigButton>;
}

export default LogOutButton;
