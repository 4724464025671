import { cloneElement } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthStates } from "../../types";
import { useAuthContext } from "../../lib/contextLib";

function queryString(name, url=window.location.href) {
  return new URLSearchParams(new URL(url)).get(name)
}

export default function UnauthenticatedRoute(props) {
  const { children, component, ...rest } = props;
  const { authState } = useAuthContext();
  
  const redirect = queryString("redirect")
  if (component !== undefined) {
    return (
      <Route {...rest}>
        {authState == AuthStates.notSignedIn ? component(rest) : <Redirect to={redirect ?? "/"} />}
      </Route>
    );
  }

  return (
    <Route {...rest}>
      {authState == AuthStates.notSignedIn ? cloneElement(children, props) : <Redirect to={redirect ?? "/"} />}
    </Route>
  );
}