import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppContainer from './AppContainer';
import reportWebVitals from './reportWebVitals';
import { Amplify, API, Auth } from 'aws-amplify';
import config from './amplifyConfig';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    oauth: {
      domain: "4-sight-azuretest.auth.us-east-1.amazoncognito.com",
      scope: ["email", "openid", "aws.cognito.signin.user.admin", "profile"],
      redirectSignIn: window.location.origin,
      redirectSignOut: window.location.origin,
      responseType: "code"
    }
  },
API: {
    endpoints: [
      {
        name: "dev-foursightauthhelper",
        endpoint: "https://almw7ui6nj.execute-api.us-east-1.amazonaws.com",
        // custom_header: async () => {
        //   return {
        //     Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        //   }
        // }
      },
      {
        name: "dev-foursightappid",
        endpoint: "https://0gnsapitjb.execute-api.us-east-1.amazonaws.com",
        // custom_header: async () => {
        //   return {
        //     Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
        //   }
        // }
      },
    ]
  }
});

ReactDOM.render(
  <React.StrictMode>
    <AppContainer />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();