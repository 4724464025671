import "./RawData.scss"
import { useState, useEffect } from 'react';
import { qlikIdentity, qlikTenantDomain } from "../lib/qlikLib";
import { useIsEnigmaConnected, useIsOnline } from "../lib/hooks";
import { tryLogin, getSpaceId, getAppId, getSheetId } from '../lib/qlikHelper';

const LOGIN_REFRESH_RATE = 1000 * 60 * 60;
var loginRefreshInterval;

export default function RawData(props) {
  const [iFrameURL, setIFrameURL] = useState()

  async function asyncSetup() {
    if (loginRefreshInterval == undefined) {
      loginRefreshInterval = setInterval(async () => {
        try {
          await tryLogin();
        } catch (error) {
          clearInterval(loginRefreshInterval);
        }
      }, LOGIN_REFRESH_RATE);
    }

    await tryLogin();

    // Resume the rendering process
    const spaceId = await getSpaceId(0);

    // The primary app to show is always called "Primary"
    const appId = await getAppId(spaceId, "Primary");
    const sheetId = await getSheetId(appId, "Raw Data");

    updateIframeURL(appId, sheetId);
  }

  function updateIframeURL(appId, sheetId) {
    const frameUrl = `https://${qlikTenantDomain}/single/?appid=${appId}&sheet=${sheetId}&opt=ctxmenu,currsel&identity=${qlikIdentity}`;
    setIFrameURL(frameUrl)
  }

  const isOnline = useIsOnline();
  const isEnigmaConnected = useIsEnigmaConnected();

  useEffect(() => {
  asyncSetup();
}, [])
  if (!isOnline) {
    return <div>
      <h1>Your browser is offline.</h1>
      <p>This visualisation requires an active connection.</p>
      <p>When your browser reconnects to the internet this visualisation will be available.</p>
    </div>
  }

  if (!isEnigmaConnected) {
    return <div>
      <p>Please reload the page.</p>
    </div>
  }

  return <div className="raw-data-container">
    <iframe className="raw-data-iframe" src={iFrameURL} ></iframe>
  </div>
}