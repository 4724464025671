import "./App.css"
import React, { useState, useEffect, createElement } from "react";
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import Header from "./components/Header";
import Home from "./pages/Home/Home";
import Setting from "./pages/Setting/Setting";
import RawData from "./components/RawData";
import VehicleOverview from "./pages/VehicleOverview/VehicleOverview";
import Login from "./pages/Login/Login";
import AuthenticatedRoute from "./components/Routes/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/Routes/UnauthenticatedRoute";
import { getSignedQLikJWT, loginToQlikWithToken, isLoggedIn, getQlikOverview } from "./lib/qlikLib";

import { AuthContext, useAuthContext } from "./lib/contextLib";
import { Auth, Hub } from "aws-amplify";
import { findSpaceByIndex, findSpaceByName, getSpaceId, tryLogin } from "./lib/qlikHelper";
import { ErrorBoundary } from "react-error-boundary";
import LogOut from "./components/buttons/LogOutButton";
import { AuthStates } from "./types";
import ClientLoaderAnimation from "./components/loaders/ClientLoaderAnimation";

async function getCurrentOrg() { // Function is only used during development
  try {
    return "mvp";
    const space = await findSpaceByIndex(0); // A hack when the userInfo API was not available
    console.log("Fetched space successfully")
    return space.name; // Assumes that Qlik space names are an exact match for client customer codes
  } catch (e) {
    console.log("Failed to fetch first space");
  }

  return null;
}


function App() {
  const { authState, setAuthState } = useAuthContext();
  const [currentOrg, setCurrentOrg] = useState(null);
  const ClientLoader = React.lazy(() => import(/* webpackChunkName: "companyroute" */ "./clients/ClientLoader"));

  // Throw unhandled errors so they bubble to higher error boundaries
  const [unhandledError, setUnhandledError] = useState(null);
  if (unhandledError) {
    throw unhandledError;
  }

  useEffect(() => {
    async function onPageLoad() {
      try {
        await Auth.currentSession()

        // A user is logged into Cognito and to QLik
        setAuthState(AuthStates.signedIn)
      } catch (e) {
        console.error("[App.js] onLoad: ", e)
        setAuthState(AuthStates.notSignedIn)
        setCurrentOrg(null)
      }
    }

    async function afterSignIn() {
      await tryLogin();
      const currentOrganisation = await getCurrentOrg();
      setCurrentOrg(currentOrganisation);
    }

    if (authState == AuthStates.signedIn)
      afterSignIn().catch(error => setUnhandledError(error));

    if (authState == AuthStates.loadingData)
      onPageLoad();
  }, [authState, setAuthState]);

  if (authState == AuthStates.loadingData) return <></>;

  return (
    <BrowserRouter>
        <Switch>
          <UnauthenticatedRoute path="/login" component={Login} />
          <AuthenticatedRoute path="/">
            <div className="App">
              {/* <Header /> */}
              {
                currentOrg != null &&
                  <React.Suspense fallback={<ClientLoaderAnimation />}>
                    <ClientLoader customerCode={currentOrg} />
                  </React.Suspense>
              }
              {
                currentOrg == null &&
                <ClientLoaderAnimation />
              }
            </div>
          </AuthenticatedRoute>
        </Switch>
    </BrowserRouter>
  );
}

export default App;