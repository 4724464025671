import "./ClientLoaderAnimation.scss";
import React, { useState, useEffect } from 'react';

const loadingVideos = [
  "https://cdn-icons-mp4.flaticon.com/512/6172/6172518.mp4",
  "https://cdn-icons-mp4.flaticon.com/512/6569/6569133.mp4"
]

const messages = [
  "Securing your environment...", "Loading client information...",
  "Establishing new secure connection...", "Authenticating client session...",
  "Validating company access...", "Accessing role policy...",
  "Validating security tokens...", "Collecting application state...",
  "Dynamically fetching chunks...", "Collecting segmented environment chunks...",
  "Evaluating dependencies..."
]

export default function ClientLoaderAnimation(props) {
  const [currentMessage, setCurrentMessage] = useState("")

  useEffect(() => {
    // Change the message periodically
    const intervalID = setInterval(() => {
      setCurrentMessage(messages[Math.floor(Math.random() * messages.length)])
    }, 3000)

    return () => {
      clearInterval(intervalID)
    }
  }, [])

  return <div className="ClientLoaderAnimation">
    <video loop autoPlay muted className="ClientLoaderAnimation__video">
      <source src={loadingVideos[0]} type="video/mp4" />
    </video>
    <div className="ClientLoaderAnimation__message">
      <p>{currentMessage}</p>
    </div>
  </div>
}