import { Route, Redirect, useLocation } from "react-router-dom"
import { AuthStates } from "../../types"
import { useAuthContext } from "../../lib/contextLib"

export default function AuthenticatedRoute({ children, component, ...rest }) {
  const { pathname, search } = useLocation()
  const { authState } = useAuthContext()

  if (component !== undefined) {
    return <Route {...rest}>
      {authState == AuthStates.signedIn ? component(rest) : <Redirect to={`/login?redirect=${pathname}${search}`} />}
    </Route>
  }

  return <Route {...rest}>
    {authState == AuthStates.signedIn ? children : <Redirect to={`/login?redirect=${pathname}${search}`} />}
  </Route>
}