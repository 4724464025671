/**
 * Enum for auth state
 * 
 * 00 - not signed in. not loading auth data.
 * 01 - not signed in.     loading auth data.
 * 10 -     signed in.     loading auth data complete.
 * @readonly
 * @enum {0|1|2}
 */
export const AuthStates = {
  notSignedIn: 0b00, // 0
  loadingData: 0b01, // 1
  signedIn: 0b10  // 2
}