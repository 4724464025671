import App from './App';
import { ErrorBoundary } from "react-error-boundary";
import { AuthStates } from "./types";
import { useState } from 'react';
import { AuthContext } from "./lib/contextLib";
import LogOut from './components/buttons/LogOutButton';
import BigButton from "./components/buttons/BigButton";
import "./AppContainer.scss";
import SymboticwareLogo from './components/SymboticwareLogo';

export default function AppContainer(props) {
  const [authState, setAuthState] = useState(AuthStates.loadingData);

  return (
    <AuthContext.Provider value={{ authState, setAuthState }}>
      <ErrorBoundary
        FallbackComponent={AppContainerFallback}
      >
        <App />
      </ErrorBoundary>

    </AuthContext.Provider>
  )
}


function AppContainerFallback(props) {
  const { error } = props;

  return <div className="AppContainerFallback">
    <div className="AppContainerFallback__dialog">
      <div className="AppContainerFallback__dialog__logo">

        {/* Symboticware logo */}
        <SymboticwareLogo grayScale={100} opacity={0.2} />
      </div>

      {/* Text content  */}
      <div style={{ height: "80%" }}>
        {/* Description */}
        <h1 className="AppContainerFallback__dialog__text">Something went wrong</h1>
        <br />
        <p className="AppContainerFallback__dialog__text">Please try reloading the page. If this error persists, please contact
        us at <a href="mailto:support@symboticware.com">support@symboticware.com</a>, and our team will help you as soon as possible.</p>
        <br />

        {/* Action buttons */}
        <div className="AppContainerFallback__dialog__action">
          <div className="AppContainerFallback__dialog__action__button" style={{ marginRight: "1rem" }}>
            <BigButton
              onClick={props.resetErrorBoundary}
              style={{
                marginTop: "42px",
                color: "#282c34"
              }}>Try again</BigButton>
          </div>
          <div className="AppContainerFallback__dialog__action__button"><LogOut /></div>
        </div>
      </div>

      {/* Symboticware logo */}
      <SymboticwareLogo />
    </div>

    <div className="AppContainerFallback__error">
      <p>Error log:</p>
      <code>
        <p>{error.message}</p>
        <p>{error.stack}</p>
      </code>
    </div>
  </div>
}