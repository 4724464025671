function Setting(){
    return(
        <>
            <div className = "sightai-logo" >
                <h3>Setting</h3>
            </div>
        </>
    )
}

export default Setting; 