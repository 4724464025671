// import { Link } from "react-router-dom";
import { Component } from "react";
import car from "../../assets/789d-500x500.png";
import map from "../../assets/location.png";
import direction from "../../assets/direction.png";
import "./Home.scss";

class Home extends Component {
  render() {
    return (
      <>
        <div className="home-component">
          <div className="home-components">
            <div className="home-component__top">
              <h3 className="home-title">Total Machine Hours</h3>
              <h3>1 Hrs</h3>
            </div>
            <div className="home-component__top">
              <h3 className="home-title">Total Fuel Used</h3>
              <h3>100 liters</h3>
            </div>
            <div className="home-component__top">
              <h3 className="home-title">Time Since Last Maintenance</h3>
              <h3>1 Hrs</h3>
            </div>
          </div>
          <div className="home-component__middle">
            <div className = "home-img">
              <h3 className = "img-title">A34078</h3>
              <img className = "home__truck-img" src={car} alt="truck" />
            </div>
            <div className = "home-sub__division">
              <div className = "written">
                <h3>Make</h3>
                <h3>Cat</h3>
              </div>
              <div className = "written">
                <h3>Asset Year</h3>
                <h3>2015</h3>
              </div>
              <div className = "written">
                <h3>Model</h3>
                <h3>R1700</h3>
              </div>
            </div>
          </div>
          <div className="home-component__botton">
                <div className = "home-location">
                    <h3 className = "location-title">Location</h3>
                    <img className = "gps-location" src={map} alt="GPS-location" />
                </div>
                <div className = "home-component__direction">
                    <h3 className = "GPS">Location of A34078</h3>
                    <h3 className = "GPS">Site #3</h3>
                    <h3 className = "GPS">39 39 37.5N 121 20'17.6W</h3>
                    <img className = "GPS-img" src={direction} alt="direction" />
                </div>
          </div>
        </div>
      </>
    );
  }
}

export default Home;
