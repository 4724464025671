import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAuthContext } from "../../lib/contextLib";
import { Auth, Hub } from "aws-amplify";
import "./Login.scss";
import ForestryIMG from "./Forestry.png";
import SYM_LogoSVG from "./New-SYM-Logo-white.svg";
import { CognitoUser } from "amazon-cognito-identity-js";
import { AuthStates } from "../../types";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";

function Login({ height, logo, logoAlt, slogan, width }) {
  const { setAuthState } = useAuthContext();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberDevice, setRememberDevice] = useState(false);

  const clearUsername = () => setUsername("");
  const clearPassword = () => setPassword("");
  const clearRememberDevice = () => setRememberDevice(false);

  function handleSubmit(event) {
    event.preventDefault();
    return onUserPassLogin({
      username,
      password,
      rememberDevice,
      clearUsername,
      clearPassword,
      clearRememberDevice,
    });
  }

  /**
   * Log in with Cognito
   * @param {{username: string, password: string}} options An object containing a username and password
   * @returns A Promise
   */
  function onUserPassLogin({ username, password }) {

    if (username == null || password == null) {
      return Promise.reject("Username or password is null");
    }

        return Auth.signIn(username, password)
      .then((/** @type {CognitoUser} */ user) => {
        // Resolve any challenges that the user has with their account
        // TODO: Look into accessing the challenge type from an enum
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          // Set the current users password to the current password
          // TODO: This opens a security hole if passwords are not randomly generated by the admin.
          //       This will need to be replaced with a new-password prompt
          return Auth.completeNewPassword(user, password);
        }

        return user;
      })
      .then((_user) => {
        setAuthState(AuthStates.signedIn);
      })
      .catch((error) => {
        setAuthState(AuthStates.notSignedIn);
        alert("Failed to log in", error);
        console.error(error);
      });
  }

  function onForgotPassword() {}
  function onThirdPartyLogin() {
    Auth.federatedSignIn();
  }

  let clientId = `4v4qsp6l9h70rn0dhnlhknbfb9`;

  return (
    <div id="login-screen" style={{ backgroundImage: `url(${ForestryIMG}` }}>
      <form
        id="login-form"
        style={height || width ? { height, width } : undefined}
        onSubmit={handleSubmit}
      >
        <img src={SYM_LogoSVG} alt="Symbioticware" id="login-form-logo" />
        <div className="tagline">Your Operating System of Intelligence</div>
        <input
          placeholder="Username"
          type="text"
          name="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          placeholder="Password"
          type="password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        <a
          className="third-party-login"
          onClick={onThirdPartyLogin}
        >
          Login Via Third Party
        </a>
        <a className="reset-password" onClick={onForgotPassword} href="/forgot">
          Forgot your password?
        </a>

        {/* TODO: Move this checkbox to a reusable component */}
        <label id="login-remember">
          <input
            type="checkbox"
            name="remember"
            value={rememberDevice}
            onChange={(e) => setRememberDevice(e.target.value)}
          />
          <span>Remember this device</span>
        </label>

        <button type="submit" id="login-form-submit">
          Login
        </button>
      </form>
    </div>
  );
}

Login.propTypes = {
  height: PropTypes.string,
  logo: PropTypes.string,
  logoAlt: PropTypes.string,
  slogan: PropTypes.string,
  width: PropTypes.string,
};

export default Login;
